import {
  Divider,
  Typography,
  styled,
  Link,
  Button,
  PaginationItem,
} from "@mui/material";
export const StyledDivider = styled(Divider)(() => ({
  border: "0.5px solid #efefef",
  height: "90%",
}));

export const StyledProfileName = styled("span")(
  ({ fontWeight, fontSize, lineHeight, color }) => ({
    display: "block",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: fontWeight,
    fontSize: fontSize,
    lineHeight: lineHeight,
    textTransform: "capitalize",
    color: color,
  })
);
export const StyledCardCounts = styled("div")(() => ({
  fontFamily: "Open Sans",
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: " 18px",
  lineHeight: "25px",
  color: "#474747",
}));

export const StyledCardText = styled("div")(() => ({
  fontFamily: "Open Sans",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: " 14px",
  lineHeight: "19px",
  color: "#5C5C5C",
}));
export const StyledCardTitle = styled(Typography)(() => ({
  color: "#474747",
  fontFamily: "Open Sans",
  fontStyle: "normal",
  fontSize: "14px",
  fontWeight: "600",
}));
export const StyledLink = styled(Link)(() => ({
  color: "#474747",
  fontFamily: "Open Sans",
  fontStyle: "normal",
  fontSize: "14px",
  fontWeight: "600",
}));

export const StyledExport = styled(Button)(() => ({
  gap: 5,
  textTransform: "capitalize",
  backgroundColor: "#C679F6",
  fontFamily: "Open Sans",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "bold",
  color: "#FFF",
  padding: "7px 13px 5px 13px",
  "&:hover": {
    color: "#FFFFFF",
    backgroundColor: "#C679F6",
  },
}));
export const StyledSubmitButton = styled(Button)(() => ({
  height: "44px",
  mb: 2,
  border: "1px solid #aea8b3",
  backgroundColor: "#C679F6",
  ":hover": {
    boxShadow: " 0px 10px 20px rgba(0, 0, 0, 0.15)",
    backgroundColor: "#C679F6",
    color: "white",
  },
  boxShadow: " 0px 10px 20px rgba(0, 0, 0, 0.15)",
  textTransform: "none",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "16px",
  color: "#FFFFFF",
}));
export const StyledPaginationItem = styled(PaginationItem)(({ item }) => ({
  color: "#C679F6",
  border: item.type === "page" ? "1px solid #ECEBEB" : "none",
  "&.Mui-selected": {
    color: "white",
  },
}));

export const StyledIconText = styled(Typography)(
  ({ backgroundColor, textcolor }) => ({
    width: "45px",
    height: "45px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: backgroundColor,
    color: textcolor,
    fontFamily: "Open Sans",
    fontSize: "18.36px",
    fontWeight: 600,
  })
);
