import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AppLogo from "../../assets/images/logo.svg";
import LayoutLogo from "../../assets/images/topDesign.svg";
import "../..//App.css";
import TextInputLable from "../../components/textField";
import PasswordArea from "../../components/passwordField";
import LeftSideImage from "../../commonUses/leftSideImage";
import MailIcon from "../../assets/images/mailIcon.svg";
import LockIcon from "../../assets/images/lockIcon.svg";
import { useNavigate } from "react-router-dom";
import { loginApi } from "../../api/adminApi";
import { Helmet } from "react-helmet-async";
import { Link } from "@mui/material";
import { StyledSubmitButton } from "../../StyledComponents/StyledComponents";

export default function SignInSide() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const handleEmailChange = (e) => {
    const inputValue = e.target.value;
    setEmail(inputValue);
    setEmailError("");
  };

  const handlePasswordChange = (e) => {
    const inputValue = e.target.value;
    setPassword(inputValue);
    setPasswordError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let credantial = {
      email,
      password,
    };
    let apiData = await loginApi(credantial);
    switch (apiData?.data?.message) {
      case "Invalid Email":
        setEmailError("Invalid Email Address!");
        setPasswordError("Wrong Password!");

        break;
      case "Invalid Password":
        setPasswordError("Wrong Password!");
        break;
      case "you don't have an access.contact your admin":
        setEmailError("Access Denied");
        break;
      default:
        if (apiData?.data?.statusCode === 200) {
          navigate("/Dashboard");
        }
        setPasswordError("");
        setEmailError("");
        break;
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  let regex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
  var textFieldColor = regex.test(email)
    ? emailError === ""
      ? "#FBF5FF"
      : "white"
    : "white";
  var passwordColor =
    password.length > 7
      ? passwordError === ""
        ? "#FBF5FF"
        : "white"
      : "white";

  var styles;
  if (showPassword === true) {
    styles = {
      color: "#8B8B8B",
    };
  } else {
    styles = {
      color: "#CBCBCB",
    };
  }
  var imageVisible;
  if (email.length < 1) {
    imageVisible = MailIcon;
  } else {
    imageVisible = null;
  }
  var passwordVisible;
  if (password.length < 1) {
    passwordVisible = LockIcon;
  } else {
    passwordVisible = null;
  }
  return (
    <>
      <Helmet>
        <title> Duco Insure </title>
      </Helmet>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <LeftSideImage />
        <Grid item xs={12} sm={12} md={12} lg={5}>
          <img src={LayoutLogo} alt="Layout" style={{ float: "right" }} />

          <Grid container direction="column" alignItems="center" rowGap={3}>
            <Grid item>
              <img src={AppLogo} alt="AppLogo" />
            </Grid>
            <Grid item>
              <Typography component={"span"} variant={"body2"}>
                <p className="topFont">Welcome Back!</p>
              </Typography>
            </Grid>
            <Grid item>
              <Typography component={"span"} variant={"body2"}>
                <p className="topNextText">Sign In</p>
              </Typography>
            </Grid>
            <Grid item>
              <form onSubmit={handleSubmit}>
                <Grid container direction={"column"} rowSpacing={3}>
                  <Grid item>
                    <label className="lableText">Email Address</label>
                    <TextInputLable
                      email={email}
                      emailChange={handleEmailChange}
                      error={Boolean(emailError)}
                      helperText={emailError}
                      imageVisible={imageVisible}
                      textFieldColor={textFieldColor}
                    />
                  </Grid>
                  <Grid item>
                    <label className="lableText">Password</label>
                    <PasswordArea
                      show={showPassword}
                      password={password}
                      handlePasswordChange={handlePasswordChange}
                      error={Boolean(passwordError)}
                      helperText={passwordError}
                      onClick={handleTogglePasswordVisibility}
                      styleColor={styles}
                      passwordVisible={passwordVisible}
                      passwordColor={passwordColor}
                    />
                  </Grid>
                  <Grid item>
                    <StyledSubmitButton type="submit" fullWidth>
                      Sign in
                    </StyledSubmitButton>
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Link href="sendOtp">Change Password!</Link>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
