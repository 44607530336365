import { Grid, useMediaQuery, useTheme } from "@mui/material";
import personIcon from "../../assets/images/personIcon.svg";
import DucousersIcon from "../../assets/images/DucousersIcon.svg";
import uiiUsersIcon from "../../assets/images/uiiUsersIcon.svg";
import SingleCards from "./singleCardRowContents";
const FirstRowCards = (props) => {
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      rowSpacing={2}
      columnSpacing={2}
      sx={{
        // paddingLeft: "25px",
        // paddingRight: "25px",
        justifyContent: "center",
        marginTop: isSmallScreen
          ? "-70px"
          : isExtraSmallScreen
          ? "0px"
          : "-100px",
      }}
    >
      <SingleCards
        chartLimitValue={props.totalActiveChartValue}
        chartPercentage={props.totalActiveChartValue}
        donutColor={"#FF69B4"}
        cardIcon={personIcon}
        textcolor={""}
        count={props.totalActiveCount}
        title={"Total Active Users"}
      />
      <SingleCards
        chartLimitValue={props.ducoUsersChartLimitValue}
        chartPercentage={props.ducoUsersChartLimitValue}
        donutColor={"#31E4A8"}
        cardIcon="D"
        backgroundColor={"rgba(49, 228, 168, 0.10)"}
        textcolor={"#31E4A8"}
        count={props.ducoRegisterUsers}
        title={"Duco Active Users"}
      />
      <SingleCards
        chartLimitValue={props.uiiUsersChartLimitValue}
        chartPercentage={props.uiiUsersChartLimitValue}
        donutColor={"#615CFF"}
        cardIcon={uiiUsersIcon}
        textcolor={""}
        count={props.migratedFromUiiUsers}
        title={"UII Active Users"}
      />
    </Grid>
  );
};
export default FirstRowCards;
