// import { CardContent } from "@material-ui/core";
import { CardContent, Typography } from "@mui/material";
import { Card, Grid } from "@mui/material";
import { CircularProgressbar } from "react-circular-progressbar";
import {
  StyledCardCounts,
  StyledCardText,
  StyledIconText,
} from "../../StyledComponents/StyledComponents";
const SingleCardContents = (props) => {
  function TopIcons() {
    return (
      <>
        {props.cardIcon.length <= 1 ? (
          <StyledIconText
            sx={{
              marginBottom: "4px",
            }}
            backgroundColor={props.backgroundColor}
            textcolor={props.textcolor}
          >
            {props.cardIcon}
          </StyledIconText>
        ) : (
          <img
            style={{ width: "45px", height: "45px" }}
            src={props.cardIcon}
            alt="Image"
          />
        )}
      </>
    );
  }
  return (
    <Grid item xs={12} sm={10} md={6} lg={4} xl={2.4}>
      <Card
        variant="outlined"
        sx={{
          height: "100%",
          borderRadius: "10px",
          boxShadow: "0px 6px 40px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* <CardContent> */}
        <Grid
          container
          direction={"column"}
          rowGap={3}
          justifyContent="space-between"
          sx={{ padding: "15px" }}
        >
          <Grid item>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "15px",
                backgroundColor: "#F9F9F9",
                borderRadius: "6px",
                gap: "1rem",
              }}
            >
              <TopIcons />
              <StyledCardText>{props.title}</StyledCardText>
              <StyledCardCounts>{props.count}</StyledCardCounts>
            </div>
          </Grid>
          <Grid item container justifyContent="center">
            <CircularProgressbar
              value={props.chartLimitValue}
              text={`${props.chartPercentage}%`}
              strokeWidth={11}
              styles={{
                root: { width: "110px", height: "110px" },
                path: {
                  stroke: props.donutColor,
                  strokeLinecap: "round",
                  transition: "stroke-dashoffset 1s ease 0s",
                  strokeWidth: 11,
                },
                trail: { stroke: "#E3E4E4" },
                text: {
                  fill: "black",
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: "300",
                  fontSize: " 12px",
                  lineHeight: "16px",
                  textAnchor: "middle",
                  transform: "translate(1.5px, 5px)",
                },
              }}
            />
          </Grid>
        </Grid>
        {/* </CardContent> */}
      </Card>
    </Grid>
  );
};
export default SingleCardContents;
