import {
  Card,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import "../../App.css";
import IosShareIcon from "@mui/icons-material/IosShare";
import {
  StyledCardTitle,
  StyledExport,
} from "../../StyledComponents/StyledComponents";
import { Search } from "@mui/icons-material";
import StickyHeadTable from "../../components/table";
import SelectLabels from "../../components/menuItem";
import { AppContext } from "../../Context/AppContext";
import { useNavigate } from "react-router-dom";
import { planDetailsApi } from "../../api/adminApi";
import DateRangeSelector from "../../components/datePicker";
import { Helmet } from "react-helmet-async";
import { debounce } from "lodash";

export default function PlanDetails() {
  const { limit } = useContext(AppContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("md"));
  const drawerWidth = 0;
  const [searchText, setSearchText] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [Loader, setLoader] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [page, setPage] = useState(0);
  const [planData, setData] = useState([]);
  const [planCount, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const noDataFound  = planData.length === 0;
  
  const resultLimit = limit === "" ? 10 : limit;
  function formatDate(inputDateStr) {
    if (inputDateStr?.$d !== undefined) {
      const dateObj = new Date(inputDateStr);
      const month = String(dateObj.getMonth() + 1).padStart(2, "0");
      const day = String(dateObj.getDate()).padStart(2, "0");
      const year = String(dateObj.getFullYear());
      return `${month}-${day}-${year}`;
    } else {
      return "";
    }
  }
  async function handleDownload() {
    setLoading(true);

    let isForSheet = "true";
    planDetailsApi(page, resultLimit, searchText, isForSheet).then(() => {
      setLoading(false);
    });
  }
  const debouncedAPICall = debounce(async (query) => {
    let isForSheet = "false";
    setIsLoading(true);
    await planDetailsApi(
      page,
      resultLimit,
      query,
      isForSheet,
      formatDate(startDate),
      formatDate(endDate)
    ).then((res) => {
      if (res?.statusCode == 404) {
        navigate("/");
      }
      setIsLoading(false);
      setData(res?.message);
      setPage(0);
      setCount(res?.count);
    });
  }, 0);
  const handleSearchChange = async (event) => {
    let { value } = event.target;
    if (value == "") {
      await debouncedAPICall(value);
    }
    setSearchText(value);
  };
  const handlePageChange = (params) => {
    setPage(params.page);
  };
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleSearchClick = async () => {
    await debouncedAPICall(searchText);
    setLoader(false);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    await debouncedAPICall(searchText);
    setIsSubmit(false);
  };

  useEffect(() => {
    let isForSheet = "false";
    setIsLoading(true);

    planDetailsApi(
      page,
      resultLimit,
      searchText,
      isForSheet,
      formatDate(startDate),
      formatDate(endDate)
    ).then((res) => {
      if (res?.statusCode == 404) {
        navigate("/");
      }
      setData(res?.message);
      setCount(res?.count);
      setLoader(false);
      setIsLoading(false);
    });
  }, [page, limit, endDate]);

  if (Loader == true) {
    return (
      <div className="ayoub">
        <div className="loader"></div>
      </div>
    );
  } else {
    return (
      <>
        <Helmet>
          <title> Plan Details </title>
        </Helmet>
        <Grid
          container
          sx={{
            p: "25px",
          }}
        >
          <Grid
            item
            sx={{
              backgroundColor: "#FAF2FF",
              width: isSmallerScreen ? "100%" : `calc(100% - ${drawerWidth}px)`,
              ml: { md: `${drawerWidth}px` },
            }}
          >
            <Grid container direction={"column"}>
              <Grid item>
                <Card>
                  <CardHeader
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    title={
                      <Grid
                        display="flex"
                        alignItems={"center"}
                        justifyContent="space-between"
                      >
                        <StyledCardTitle> Plan Details </StyledCardTitle>
                        
                        <StyledExport
                          variant="contained"
                          onClick={handleDownload}
                          disabled={noDataFound}
                          
                        >
                          {loading ? (
                            <CircularProgress
                              size={25}
                              sx={{
                                color: "white", 
                              }}
                            />
                          ) : (
                            <IosShareIcon
                              sx={{
                                width: "19px",
                                height: "19px",
                                pb: "5px",
                              }}
                            />
                          )}
                          Export
                        </StyledExport>
                        
                      </Grid>
                    }
                  />
                  <Divider />
                  <Card sx={{ height: "100%" }}>
                    
                              
                    <Grid
                      container
                      direction="row"
                      alignItems={"start"}
                      justifyContent={"space-evenly"}
                    >
                      <Grid item xl={1.8} lg={2.8} md={3.8} sm={5.9} xs={5.9}>
                        <CardHeader
                          sx={{ p: "12px" }}
                          title={
                            <Grid
                              container
                              direction={"row"}
                              columnSpacing={3}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                            >
                              <Grid item xl={4.5} lg={4} md={4} sm={4} xs={5}>
                                <Typography
                                  sx={{
                                    color: "#A6A4A4",
                                  }}
                                >
                                  Results:
                                </Typography>
                              </Grid>
                              <Grid item xl={7.5} lg={8} md={8} sm={8} xs={7}>
                                <SelectLabels />
                              </Grid>
                            </Grid>
                          }
                        />
                      </Grid>
                      {/* {isSmallerScreen ? null : ( */}
                      <Grid
                        item
                        xl={0.01}
                        lg={0.01}
                        md={0.01}
                        sm={0.01}
                        xs={0.01}
                      >
                        <Divider
                          sx={{
                            height: "80px",
                          }}
                          orientation="vertical"
                        />
                      </Grid>
                      {/* )} */}
                      {isSmallerScreen ? null : (
                        <Grid item xl={6} lg={5} md={4} xs={4}>
                          <CardHeader
                            sx={{ p: "12px" }}
                            title={
                              <form onSubmit={handleSearchSubmit}>
                                <TextField
                                  sx={{ mr: 1 }}
                                  id="outlined-basic"
                                  className="search-input"
                                  placeholder="Search..."
                                  autoComplete="off"
                                  // value={searchText}
                                  onChange={handleSearchChange}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton onClick={handleSearchClick}>
                                          <Search />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </form>
                            }
                          />
                        </Grid>
                      )}
                      {isSmallerScreen ? null : (
                        <Grid
                          item
                          xl={0.01}
                          lg={0.01}
                          md={0.01}
                          sm={0.01}
                          xs={0.01}
                        >
                          <Divider
                            sx={{
                              height: "80px",
                            }}
                            orientation="vertical"
                          />
                        </Grid>
                      )}
                      {/* {isSmallerScreen ? null : ( */}
                      <Grid item xl={4} lg={4} md={4} sm={6} xs={6}>
                        <CardHeader
                          sx={{ p: "12px" }}
                          title={
                            <DateRangeSelector
                              endDate={endDate}
                              setEndDate={setEndDate}
                              setStartDate={setStartDate}
                              startDate={startDate}
                              handleStartDateChange={handleStartDateChange}
                              handleEndDateChange={handleEndDateChange}
                            />
                          }
                        />
                      </Grid>
                      {/* )} */}
                    </Grid>
                  </Card>
                </Card>
              </Grid>
              <StickyHeadTable
                handlePageChange={handlePageChange}
                planData={planData}
                planCount={planCount}
                page={page}
                resultLimit={resultLimit}
                setPage={setPage}
                Loader={Loader}
                isLoading={isLoading}
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}
