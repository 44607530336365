import React, { useState } from "react";
import {
  Avatar,
  Grid,
  PaginationItem,
  Paper,
  Skeleton,
  ThemeProvider,
  Typography,
  createTheme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import planActiveImage from "../assets/images/planActiveImage.svg";
import planExpiredImage from "../assets/images/planStatus.svg";
import { DataGrid } from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import SilverPlan from "../assets/images/Silver.svg";
import GoldPlan from "../assets/images/GoldImage.svg";
import PlatinumPlan from "../assets/images/PlatinumImage.svg";
import TrialPlan from "../assets/images/Trial.svg";
import PencilIcon from "../assets/images/pencilIcon.svg";
import { StyledPaginationItem } from "../StyledComponents/StyledComponents";
export default function StickyHeadTable(props) {
  const {
    planCount,
    resultLimit,
    planData,
    page,
    handlePageChange,
    isLoading,
    setPage,
  } = props;
  const endPageCount = page + 1;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const customLocaleText = {
    noResultsOverlayLabel: "No Data Found",
  };
  const columns = [
    {
      field: "Profile",
      headerName: "Image",
      minWidth: 69,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Avatar
          src={params.value !== "" ? params.value : null}
          style={{
            width: "40px",
            height: "40px",
          }}
          alt="Image"
        />
      ),
    },
    {
      field: "Name",
      headerName: "Name",
      headerClassName: "super-app-theme--header",
      minWidth: 170,
    },
    {
      field: "DateOfBirth",
      headerName: "DOB",
      headerClassName: "super-app-theme--header",

      minWidth: 90,
    },
    {
      field: "Mobile",
      headerName: "Mobile No.",
      headerClassName: "super-app-theme--header",

      minWidth: 120,
    },
    {
      field: "Email",
      headerName: "Mail Id",
      headerClassName: "super-app-theme--header",
      minWidth: 200,
      // renderCell: (params) =>
      // <Typography
      // sx={{
      //   fontSize: "14px",
      //   color: "#66ADFF",
      // }}
      // >
      // params.value,
      // </Typography>
    },
    {
      field: "Address",
      headerName: "Address",
      headerClassName: "super-app-theme--header",

      minWidth: 220,
    },
    {
      field: "Signature",
      headerName: "Signature",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      renderCell: (params) => (
        <Avatar
          src={params.value !== "" ? params.value : null}
          style={{
            width: "40px",
            height: "40px",
          }}
          alt="Image"
        />
      ),
    },
    {
      field: "CompanyName",
      headerName: "Insurance Companies",
      headerClassName: "super-app-theme--header",
      minWidth: 180,
    },
    {
      field: "LastPlanStatus",
      headerName: "Plan Status",
      headerClassName: "super-app-theme--header",

      minWidth: 160,
      renderCell: (params) => (
        <img
          src={params.value === "Active" ? planActiveImage : planExpiredImage}
          alt="Image"
        />
      ),
    },
    {
      field: "LastPlanName",
      headerName: "Latest Plan",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        let imageSource;

        switch (params.value) {
          case "Silver":
            imageSource = SilverPlan;
            break;
          case "Gold":
            imageSource = GoldPlan;
            break;
          case "Platinum":
            imageSource = PlatinumPlan;
            break;
          case "nill":
            imageSource = TrialPlan;
            break;
          case "Trial":
            imageSource = TrialPlan;
            break;
          default:
            imageSource = null;
            break;
        }

        return <img src={imageSource} alt="Image" />;
      },

      minWidth: 160,
    },
    {
      field: "LastPlanStartDate",
      headerName: "Start Date",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      valueGetter: (params) => {
        // const rawDate = params.getValue(params.id, "LastPlanStartDate");
        const formattedDate = new Date(params.value).toLocaleDateString(
          "en-US",
          {
            year: "numeric",
            month: "long",
            day: "numeric",
          }
        );
        return formattedDate;
      },
    },
    {
      field: "LastPlanEndDate",
      headerName: "End Date",
      headerClassName: "super-app-theme--header",

      minWidth: 120,
    },
    {
      field: "LastPlanAmount",
      headerName: "Amount Paid",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      renderCell: (params) => <p>Rs.{params.value}</p>,
    },
    {
      field: "PreviousPlanName",
      headerName: "Previous Plan",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        let imageSource;

        switch (params.value) {
          case "Silver":
            imageSource = SilverPlan;
            break;
          case "Gold":
            imageSource = GoldPlan;
            break;
          case "Platinum":
            imageSource = PlatinumPlan;
            break;
          case "nill":
            imageSource = TrialPlan;
            break;
          case "Trial":
            imageSource = TrialPlan;
            break;
          default:
            imageSource = null;
            break;
        }
        if (imageSource !== null) {
          return <img src={imageSource} alt="Image" />;
        } else {
          return "-";
        }
      },

      minWidth: 160,
    },
    {
      field: "PreviousPlanStartDate",
      headerClassName: "super-app-theme--header",
      headerName: "Start Date",
      minWidth: 90,
      renderCell: (params) =>
        params.formattedValue !== undefined ? params.formattedValue : "-",
      // null,
    },
    {
      field: "PreviousPlanEndDate",
      headerClassName: "super-app-theme--header",
      headerName: "End Date",
      minWidth: 90,
      renderCell: (params) =>
        params.formattedValue !== undefined ? params.formattedValue : "-",
    },
    {
      field: "PreviousPlanAmount",
      headerClassName: "super-app-theme--header",
      headerName: "Amount Paid",
      minWidth: 100,
      renderCell: (params) =>
        params.formattedValue !== undefined
          ? `Rs.${params.formattedValue}`
          : "-",
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      minWidth: 90,
      renderCell: (params) => <img src={PencilIcon} alt="Image" />,
    },
  ];
  const isPlanDataNotEmpty = typeof planData === "string" ? [] : planData;
  function CustomFooter() {
    const [selectedPage, setSelectedPage] = useState(1);
    const handlePage = (event, value) => {
      setSelectedPage(value);
      setPage(value - 1);
    };

    const isSmallerScreen = useMediaQuery(theme.breakpoints.down("md"));
    return (
      <Grid
        container
        style={{
          justifyContent: "space-between",
          paddingTop: "15px",
          paddingBottom: "15px",
          paddingLeft: isSmallerScreen ? "0px" : "15px",
        }}
      >
        <Grid item>
          {isSmallerScreen ? null : (
            <Typography
              sx={{
                color: "#C679F6",
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontSize: "14px",
                lineHeight: "30px",
              }}
            >
              Showing {resultLimit * page + 1} to {resultLimit * endPageCount}{" "}
              of {planCount}
            </Typography>
          )}
        </Grid>
        <Grid item>
          <Pagination
            count={Math.ceil(planCount / resultLimit)}
            shape="rounded"
            color="primary"
            page={page + 1}
            onChange={handlePage}
            boundaryCount={1}
            siblingCount={isMobile ? -1 : 1}
            renderItem={(item) => (
              <StyledPaginationItem
                item={item}
                {...item}
                sx={{
                  backgroundColor: item.selected ? "red" : "white",
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    );
  }
  if (isLoading == true) {
    return (
      // <div
      //   className="ayoub"
      //   style={{
      //     width: "100%",
      //     minHeight: "65vh",
      //     backgroundColor: "rgba(0, 0, 0, 0.5)",
      //   }}
      // >
      //   <div className="loader"></div>
      // </div>
      // <Skeleton
      //   sx={{ width: "100%", minHeight: "65vh" }}
      //   animation="wave"
      //   variant="rectangular"
      // />
      <Skeleton
        sx={{ width: "100%", minHeight: "65vh" }}
        animation="wave"
        variant="rectangular"
      ></Skeleton>
    );
  }
  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        "& .super-app-theme--header": {
          backgroundColor: "#FCF8FF",
          color: "#83868B",
          fontFamily: "Open Sans",
        },
      }}
    >
      <div style={{ height: "65vh", width: "100%" }}>
        <DataGrid
          rows={isPlanDataNotEmpty}
          columns={columns}
          pagination
          paginationMode="server"
          pageSize={resultLimit}
          onPageChange={handlePageChange}
          rowCount={10} // Set the total number of rows here
          components={{
            Footer: CustomFooter,
          }}
          sx={{
            color: "#A6A4A4",
            fontFamily: "Open Sans",
          }}
          localeText={customLocaleText}
        />
      </div>
    </Paper>
  );
}
