import SignInSide from "../pages/loginPage/loginPage";
import DashboardPage from "../pages/dashBoard/holeDashboardPage";
import PlanDetails from "../pages/planDetailsPage/planDetails";
import { Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "../pages/dashBoard/DashboardLayout";
import Page404 from "../pages/notFoundPage";
import SendOtpToMail from "../pages/forgotPasswordPage/sendOtpToMail";
import VerifyOtpFromMail from "../pages/forgotPasswordPage/verifyOtpFromMail";
import PasswordChangePage from "../pages/forgotPasswordPage/changePassword";

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: "/Dashboard",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/Dashboard/Home" />, index: true },
        { path: "Home", element: <DashboardPage /> },
        { path: "planDetails", element: <PlanDetails /> },
      ],
    },
    { path: "/", element: <SignInSide /> },
    { path: "/sendOtp", element: <SendOtpToMail /> },
    { path: "/verifyOtp", element: <VerifyOtpFromMail /> },
    { path: "/changePassword", element: <PasswordChangePage /> },
    { path: "*", element: <Page404 /> },
  ]);
  return routes;
}
