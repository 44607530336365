import React from "react";

import Grid from "@mui/material/Grid";
import SideImage from "./../assets/images/leftImage.svg";

export default function LeftSideImage() {
  return (
    <Grid
      item
      xs={false}
      // sm={4}
      md={false}
      lg={7}
      sx={{
        backgroundImage: `url(${SideImage})`,
        backgroundSize: "cover",
        backgroundPosition: "top",
      }}
    />
  );
}
