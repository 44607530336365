import Button from "@mui/material/Button";
import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AppLogo from "../../assets/images/logo.svg";
import LayoutLogo from "../../assets/images/topDesign.svg";
import "../../App.css";
import tick from "../../assets/images/tick.svg";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import LeftSideImage from "../../commonUses/leftSideImage";
import { sendOtpApi, verifyOtpApi } from "../../api/adminApi";
import { MuiOtpInput } from "mui-one-time-password-input";
import { StyledSubmitButton } from "../../StyledComponents/StyledComponents";

export default function VerifyOtpFromMail() {
  const navigate = useNavigate();
  const isVerified = sessionStorage.getItem("isVerified");
  useEffect(() => {
    if (emailFromSession == null) {
      navigate("/sendOtp");
    }
  }, []);

  const [open, setOpen] = React.useState(true);
  const [otp, setOtp] = React.useState("");
  const [error, setError] = React.useState("");
  const [resendDisabled, setResendDisabled] = React.useState(true);
  const [timer, setTimer] = React.useState(30);
  const emailFromSession = sessionStorage.getItem("Email");
  const [isVerify, setIsVerify] = React.useState(false);
  sessionStorage.setItem("isVerified", isVerify);
  const handleClose = () => {
    setOpen(false);
  };
  const action = <img src={tick}></img>;
  const message = `OTP sent to ${emailFromSession}`;
  useEffect(() => {
    let time;
    if (timer > 0) {
      time = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
    } else {
      setResendDisabled(false);
    }
    return () => clearInterval(time);
  }, [timer]);

  const handleResendClick = async () => {
    setTimer(30);
    setResendDisabled(true);
    setOpen(true);
    sendOtpApi({ email: emailFromSession });
  };
  const handleChange = (newValue) => {
    setOtp(newValue);
    setError("");
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    if (otp.length === 6) {
      let apiData = await verifyOtpApi(otp);
      switch (apiData?.data?.message) {
        case "Incorrect OTP":
          setError("*Entered OTP is incorrect");
          break;
        case "Email Already verified ":
          navigate("/sendOtp");
          break;
        default:
          if (apiData?.data?.data === "Email verified Successfully") {
            setIsVerify(true);
            navigate("/changePassword");
          } else {
            navigate("/sendOtp");
          }
      }
    } else {
      setError("*Please Enter 6 Digit OTP");
    }
  };

  // const theme = createTheme();

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <LeftSideImage />
      <Grid item xs={12} sm={12} md={12} lg={5}>
        <img src={LayoutLogo} alt="Layout" style={{ float: "right" }} />
        <div className="contain">
          <Grid
            container
            direction={"column"}
            alignItems="center"
            rowSpacing={3}
          >
            <Grid item>
              <img src={AppLogo} alt="AppLogo" />
            </Grid>
            <Grid item>
              <Grid
                container
                direction={"column"}
                alignItems="center"
                rowSpacing={5}
              >
                <Grid item>
                  <Typography component={"span"} variant={"body2"}>
                    <p className="forgotText">Change Password</p>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography component={"span"} variant={"body2"}>
                    <p className="topAfterText">
                      Enter the 6-digit code sent to your Email Address
                    </p>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <form onSubmit={onSubmit}>
                <Grid container direction={"column"} rowSpacing={5}>
                  <Grid item>
                    <MuiOtpInput
                      TextFieldsProps={{
                        size: "medium",
                        autoComplete: "off",
                        placeholder: "-",
                      }}
                      className="otpBox"
                      required
                      length={6}
                      value={otp}
                      onChange={handleChange}
                      sx={{
                        gap: "8px",
                        "& input": {
                          color: "#C679F6",
                          width: "15px",
                          height: "20px",
                        },
                      }}
                    />
                    <div className="resendButton">
                      {resendDisabled ? (
                        <Button
                          disabled
                          style={{
                            fontFamily: "Poppins",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "13px",
                            lineHeight: "20px",
                            color: "#848484",
                            textTransform: "none",
                          }}
                        >
                          Resend&nbsp;in&nbsp;
                          <span style={{ color: "#C679F6" }}>{timer}</span>
                          &nbsp;sec
                        </Button>
                      ) : (
                        <Button
                          onClick={handleResendClick}
                          style={{
                            fontFamily: "Poppins",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "13px",
                            lineHeight: "20px",
                            color: "#C679F6",
                            textTransform: "none",
                          }}
                        >
                          Resend OTP
                        </Button>
                      )}
                    </div>
                    {error && <div className="otpErrorValidation">{error}</div>}
                  </Grid>
                  <Grid item>
                    <StyledSubmitButton type="submit" fullWidth>
                      Verify
                    </StyledSubmitButton>
                  </Grid>
                </Grid>

                <Grid item>
                  <Snackbar
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleClose}
                    message={message}
                    action={action}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  />
                </Grid>
              </form>
            </Grid>
          </Grid>

          {/* </Box> */}
        </div>
      </Grid>
    </Grid>
  );
}
