import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
export default function TextInputLable(props) {
  return (
    <TextField
      name="email"
      id="email"
      variant="outlined"
      sx={{ marginTop: "4px" }}
      type="email"
      placeholder="Enter your Email Address"
      value={props.email}
      onChange={props.emailChange}
      error={props.error}
      helperText={props.helperText}
      fullWidth
      InputProps={{
        style: {
          fontSize: "14px",
          backgroundColor: props.textFieldColor,
        },
        autoComplete: "none",
        sx: {
          // width: "100%",
          height: "50px",
          borderRadius: "7px",
        },

        startAdornment: props.imageVisible ? (
          <InputAdornment position="start">
            <img src={props.imageVisible} alt="Icon" />
          </InputAdornment>
        ) : (
          ""
        ),
      }}
    />
  );
}
