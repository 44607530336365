import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export default function PasswordArea(props) {
  return (
    <TextField
      name={`random-${Math.random()}`}
      id={props.id}
      variant="outlined"
      sx={{ marginTop: "4px" }}
      type={props.show ? "text" : "password"}
      placeholder="Enter the Password"
      value={props.password}
      onChange={props.handlePasswordChange}
      error={props.error}
      helperText={props.helperText}
      fullWidth
      InputProps={{
        style: {
          fontSize: "14px",
          backgroundColor: props.passwordColor,
        },
        autoComplete: "new-password",

        sx: {
          // width: "100%",
          height: "50px",
          borderRadius: "7px",
        },
        startAdornment: props.passwordVisible ? (
          <InputAdornment position="start">
            <img src={props.passwordVisible} alt="Icon" />
          </InputAdornment>
        ) : (
          ""
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={props.onClick}
              edge="end"
              aria-label="toggle password visibility"
              style={props.styleColor}
            >
              {props.show ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
