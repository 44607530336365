import { CardContent } from "@mui/material";
import { Avatar, Card, Grid } from "@mui/material";
import { CircularProgressbar } from "react-circular-progressbar";
import {
  StyledCardCounts,
  StyledCardText,
  StyledIconText,
} from "../../StyledComponents/StyledComponents";

const SingleCards = (props) => {
  function TopIcons() {
    return (
      <>
        {props.cardIcon.length <= 1 ? (
          <StyledIconText
            sx={{
              marginBottom: "2px",
            }}
            backgroundColor={props.backgroundColor}
            textcolor={props.textcolor}
          >
            {props.cardIcon}
          </StyledIconText>
        ) : (
          <img
            src={props.cardIcon}
            alt="Image"
            style={{ width: "43px", height: "43px" }}
          />
        )}
      </>
    );
  }
  return (
    <Grid item xs={12} sm={10} md={6} lg={4}>
      <Card variant="outlined" sx={{ height: "100%", borderRadius: "10px" }}>
        <div
          style={{
            padding: "15px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <div
              style={{
                display: "grid",
                gap: "0.8rem",
              }}
            >
              <TopIcons />
              <StyledCardText>{props.title}</StyledCardText>
              <StyledCardCounts>{props.count}</StyledCardCounts>
            </div>
          </div>
          <div>
            <CircularProgressbar
              value={props.chartLimitValue}
              text={`${props.chartPercentage}%`}
              strokeWidth={11}
              styles={{
                root: { width: "110px", height: "110px" },
                path: {
                  stroke: props.donutColor,
                  strokeLinecap: "round",
                  transition: "stroke-dashoffset 1s ease 0s",
                  strokeWidth: 11,
                },
                trail: { stroke: "#E3E4E4" },
                text: {
                  fill: "black",
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: "300",
                  fontSize: " 12px",
                  lineHeight: "16px",
                  textAnchor: "middle",
                  transform: "translate(1.5px, 5px)",
                },
              }}
            />
          </div>
        </div>
      </Card>
    </Grid>
  );
};
export default SingleCards;
