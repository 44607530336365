import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AppLogo from "../../assets/images/logo.svg";
import LayoutLogo from "../../assets/images/topDesign.svg";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import LeftSideImage from "../../commonUses/leftSideImage";
import TextInputLable from "../../components/textField";
import MailIcon from "../../assets/images/mailIcon.svg";
import { sendOtpApi } from "../../api/adminApi";
import { StyledSubmitButton } from "../../StyledComponents/StyledComponents";

export default function SendOtpToMail() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const handleEmailChange = (e) => {
    const inputValue = e.target.value;
    setEmail(inputValue);
    setEmailError("");
  };
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    let apiData = await sendOtpApi({ email: email });
    if (email.length === 0) {
      setEmailError("*Please Enter Email ID");
    } else {
      switch (apiData?.data?.message) {
        case "Enter the Registered Email":
          setEmailError("*Enter the Registered Email");
          break;
        case "Access Denied":
          setEmailError("*Access Denied");
          break;
        default:
          if (apiData?.data?.statusCode === 200) {
            sessionStorage.setItem("Email", email);
            navigate("/verifyOtp");
          } else {
            setEmailError("!oops ");
          }
          break;
      }
    }
  };

  let regex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
  var textFieldColor = regex.test(email)
    ? emailError === ""
      ? "#FBF5FF"
      : "white"
    : "white";

  var imageVisible = email.length < 1 ? MailIcon : null;
  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <LeftSideImage />
      <Grid item xs={12} sm={12} md={12} lg={5}>
        <img src={LayoutLogo} alt="Layout" style={{ float: "right" }} />
        <div className="contain">
          <Grid container direction="column" alignItems="center" rowSpacing={4}>
            <Grid item>
              <img src={AppLogo} alt="AppLogo" />
            </Grid>
            <Grid item>
              <Grid
                container
                direction={"column"}
                alignItems="center"
                rowSpacing={5}
              >
                <Grid item>
                  <Typography component={"span"} variant={"body2"}>
                    <h2 className="forgotText">Change Password</h2>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography component={"span"} variant={"body2"}>
                    <p className="topAfterText">
                      An OTP will be sent for Email Verification
                    </p>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <form onSubmit={handleSubmit}>
                <Grid container direction={"column"} rowSpacing={4.5}>
                  <Grid item>
                    <label className="lableText">Email address</label>
                    <TextInputLable
                      email={email}
                      emailChange={handleEmailChange}
                      error={Boolean(emailError)}
                      helperText={emailError}
                      imageVisible={imageVisible}
                      textFieldColor={textFieldColor}
                    />
                  </Grid>
                  <Grid item>
                    <StyledSubmitButton type="submit" fullWidth>
                      Next
                    </StyledSubmitButton>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}
