import { Card, CardContent, Grid, Link } from "@mui/material";
import totalPlansLogo from "../../assets/images/totalPlanLogo.svg";
import platinumLogo from "../../assets/images/platinumLogo.svg";
import goldLogo from "../../assets/images/goldLogo.svg";
import silverLogo from "../../assets/images/silverLogo.svg";
import trialLogo from "../../assets/images/trialLogo.svg";
import SingleCardContents from "./singleCardColumnContent";
// import { CardHeader, Divider } from "@material-ui/core";
import { CardHeader, Divider } from "@mui/material";
import {
  StyledCardTitle,
  StyledLink,
} from "../../StyledComponents/StyledComponents";
const SecondRowCards = (props) => {
  return (
    <Grid
      item
      sx={{
        // paddingLeft: "25px",
        // paddingRight: "25px",
        justifyContent: "center",
        pt: "30px",
      }}
    >
      <Grid item>
        <Card
          variant="outlined"
          sx={{
            borderRadius: "10px",
          }}
        >
          <CardHeader
            sx={{ display: "flex", justifyContent: "space-between" }}
            title={
              <Grid display="flex" justifyContent="space-between">
                <StyledCardTitle>Plan Details</StyledCardTitle>
                <StyledLink href="planDetails" underline="none" color="inherit">
                  View
                </StyledLink>
              </Grid>
            }
          />
          <Divider />
          <CardContent
            sx={{
              borderRadius: "10px",
            }}
          >
            <Grid container spacing={1.5} justifyContent="center">
              <SingleCardContents
                chartLimitValue={props.allPlansChartLimitValue}
                chartPercentage={props.allPlansChartLimitValue}
                donutColor={"#FF69B4"}
                textcolor={""}
                cardIcon={totalPlansLogo}
                count={props.totalActiveCount}
                title={"Active Plan Users"}
              />
              <SingleCardContents
                chartLimitValue={props.platinumChartLimitValue}
                chartPercentage={props.platinumChartLimitValue}
                donutColor={"#7CD0EA"}
                cardIcon="P"
                backgroundColor={"rgba(124, 208, 234, 0.10)"}
                textcolor={"#7CD0EA"}
                count={props.totalPlatinum}
                title={"Total Platinum"}
              />
              <SingleCardContents
                chartLimitValue={props.goldChartLimitValue}
                chartPercentage={props.goldChartLimitValue}
                donutColor={"#E0A421"}
                cardIcon="G"
                backgroundColor={"rgba(235, 168, 23, 0.10)"}
                textcolor={"#E0A421"}
                count={props.totalGold}
                title={"Total Gold"}
              />
              <SingleCardContents
                chartLimitValue={props.silverChartLimitValue}
                chartPercentage={props.silverChartLimitValue}
                donutColor={"#B0B4C9"}
                cardIcon="S"
                backgroundColor={"rgba(176, 180, 201, 0.10)"}
                textcolor={"#B0B4C9"}
                count={props.totalSilver}
                title={"Total Silver"}
              />
              <SingleCardContents
                chartLimitValue={props.trialChartLimitValue}
                chartPercentage={props.trialChartLimitValue}
                donutColor={"#BE7CFF"}
                cardIcon="T"
                backgroundColor={"rgba(190, 124, 255, 0.10)"}
                textcolor={"#BE7CFF"}
                count={props.totalTrials}
                title={"Total Trial"}
              />
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
export default SecondRowCards;
