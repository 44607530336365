// component
import SvgColor from "../../components/svgColor/index";
import DashBoardIcon from "../../assets/images/DashBoardIcon.svg";
import ShieldIcon from "../../assets/images/ShieldIcon.svg";
import CalculatorIcon from "../../assets/images/CalculatorIcon.svg";
import LenceIcon from "../../assets/images/LenceIcon.svg";
// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={name} sx={{ width: 20, height: 20 }} />;

const navConfig = [
  {
    title: "Dashboard",
    path: "/Dashboard/Home",
    icon: icon(DashBoardIcon),
  },
  {
    title: "Plan details",
    path: "/Dashboard/planDetails",
    icon: icon(ShieldIcon),
  },
  {
    title: "Calculation details",
    path: "/Dashboard/calcDetails",
    icon: icon(CalculatorIcon),
  },
  {
    title: "Latest plan details",
    path: "/Dashboard/blog",
    icon: icon(LenceIcon),
  },
];

export default navConfig;
