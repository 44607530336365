import BellIcon from "../assets/images/bellIcon.svg";
import SettingIcon from "../assets/images/settingIcon.svg";
import "../App.css";
import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";

import {
  Avatar,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Popover,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  StyledDivider,
  StyledProfileName,
} from "../StyledComponents/StyledComponents";

const LayouAppBar = () => {
  let serializedObject = sessionStorage.getItem("userDetails");
  const userDetails = JSON.parse(serializedObject);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "popover-content" : undefined;

  return (
    <div>
      {isMobile ? (
        <></>
      ) : (
        <Grid
          container
          alignContent={"end"}
          columnSpacing={2}
          justifyContent={"space-between"}
        >
          <Grid>
            <img src={SettingIcon} alt="settings" />
          </Grid>
          <Grid item>
            <StyledDivider orientation="vertical" />
          </Grid>

          <Grid item>
            <img src={BellIcon} alt="bellIcon" />
          </Grid>
          <Grid item>
            <StyledDivider orientation="vertical" />
          </Grid>
          <Grid item>
            <Grid container direction={"row"} alignContent="center" spacing={1}>
              <Grid item>
                <Avatar
                  sx={{
                    width: "35px",
                    height: "35px",
                  }}
                  src={userDetails?.profile}
                  alt="profile"
                  onClick={handleClick}
                />
                <Dialog open={isOpen} onClose={handleClose}>
                  <DialogTitle>Profile Picture</DialogTitle>
                  <DialogContent>
                    <img
                      src={userDetails?.profile}
                      alt="profile"
                      style={{ width: "100%", height: "auto" }}
                    />
                  </DialogContent>
                </Dialog>
              </Grid>
              <Grid item style={{ paddingTop: "9%" }}>
                <StyledProfileName
                  fontWeight={500}
                  fontSize={"11px"}
                  lineHeight={"15px"}
                  color={"#232323"}
                >
                  {userDetails?.name}
                </StyledProfileName>
                <StyledProfileName
                  fontWeight={400}
                  fontSize={"9px"}
                  lineHeight={"12px"}
                  color={"#767676"}
                >
                  {userDetails?.role}
                </StyledProfileName>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {isMobile && (
        <PopupState variant="popover" popupId="demo-popup-popover">
          {(popupState) => (
            <div>
              <MoreVertIcon
                {...bindTrigger(popupState)}
                sx={{
                  color: "#C679F6",
                }}
              />

              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Grid
                  container
                  alignContent={"center"}
                  direction={"column"}
                  rowSpacing={1}
                  justifyContent={"space-evenly"}
                  sx={{ padding: "10px" }}
                >
                  <Grid>
                    <img src={SettingIcon} alt="settings" />
                  </Grid>
                  <Grid item>
                    <StyledDivider orientation="vertical" />
                  </Grid>
                  <Grid item>
                    <img src={BellIcon} alt="bellIcon" />
                  </Grid>
                  <Grid item>
                    <StyledDivider orientation="vertical" />
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction={"row"}
                      alignContent="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Avatar
                          sx={{
                            width: "35px",
                            height: "35px",
                          }}
                          src={userDetails?.profile}
                          alt="profile"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Popover>
            </div>
          )}
        </PopupState>
      )}
    </div>
  );
};
export default LayouAppBar;
