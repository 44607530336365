import React from "react";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import FirstRowCards from "./firstRow";
import SecondRowCards from "./secondRowCards";
import ThirdRowCards from "./thirdRowCards";

import mainFrame from "../../assets/images/mainFrame.svg";
import { dashboartCountApi } from "../../api/adminApi";
import { useNavigate } from "react-router-dom";

export default function DashboardPage() {
  const navigate = useNavigate();
  const [countData, setData] = useState([]);
  function percentageCalculator(value, total) {
    if (isNaN(value) || isNaN(total)) return 0;
    let percent = (value / total) * 100;
    return Math.round(percent);
  }
  useEffect(() => {
    dashboartCountApi().then((value) => {
      if (value?.statusCode == undefined || value?.statusCode == 404) {
        navigate("/");
      }
      setData(value?.data);
      return value;
    });
  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard </title>
      </Helmet>
      <Grid container>
        <Grid item style={{ width: "100%" }}>
          <img src={mainFrame} alt="mainFrame" style={{ width: "100%" }} />
        </Grid>
        <Grid item style={{ width: "100%", padding: "25px" }}>
          <FirstRowCards
            totalActiveChartValue={
              percentageCalculator(
                countData?.totalActiveUsers,
                countData?.totalActiveUsers
              ) ?? 0
            }
            ducoUsersChartLimitValue={
              percentageCalculator(
                countData?.ducoActiveUsers,
                countData?.totalActiveUsers
              ) ?? 0
            }
            uiiUsersChartLimitValue={
              percentageCalculator(
                countData?.uiiActiveUsers,
                countData?.totalActiveUsers
              ) ?? 0
            }
            ducoRegisterUsers={countData?.ducoActiveUsers ?? 0}
            totalActiveCount={countData?.totalActiveUsers ?? 0}
            migratedFromUiiUsers={countData?.uiiActiveUsers ?? 0}
          />
          <SecondRowCards
            allPlansChartLimitValue={percentageCalculator(
              countData?.totalActiveUsers,
              countData?.totalActiveUsers
            )}
            platinumChartLimitValue={percentageCalculator(
              countData?.totalPlatinum,
              countData?.totalActiveUsers
            )}
            goldChartLimitValue={percentageCalculator(
              countData?.totalGold,
              countData?.totalActiveUsers
            )}
            silverChartLimitValue={percentageCalculator(
              countData?.totalSilver,
              countData?.totalActiveUsers
            )}
            trialChartLimitValue={percentageCalculator(
              countData?.totalTrials,
              countData?.totalActiveUsers
            )}
            totalGold={countData?.totalGold ?? 0}
            totalPlatinum={countData?.totalPlatinum ?? 0}
            totalSilver={countData?.totalSilver ?? 0}
            totalTrials={countData?.totalTrials ?? 0}
            totalActiveCount={countData?.totalActiveUsers ?? 0}
          />
          <ThirdRowCards
            uiiChartLimitValue={percentageCalculator(
              countData?.totalUsersUII,
              countData?.totalCompanyUsed
            )}
            niaChartLimitValue={percentageCalculator(
              countData?.totalUsersNIA,
              countData?.totalCompanyUsed
            )}
            nicChartLimitValue={percentageCalculator(
              countData?.totalUsersNIC,
              countData?.totalCompanyUsed
            )}
            oicChartLimitValue={percentageCalculator(
              countData?.totalUsersOIC,
              countData?.totalCompanyUsed
            )}
            licChartLimitValue={percentageCalculator(
              countData?.totalUserslic,
              countData?.totalCompanyUsed
            )}
            totalUsersUII={countData?.totalUsersUII ?? 0}
            totalUsersNIC={countData?.totalUsersNIC ?? 0}
            totalUsersOIC={countData?.totalUsersOIC ?? 0}
            totalUsersNIA={countData?.totalUsersNIA ?? 0}
            totalUsersLIC={countData?.totalUserslic ?? 0}
          />
        </Grid>
      </Grid>
    </>
  );
}
