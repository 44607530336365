import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
//
import Nav from "../../components/nav/index";
import Header from "./header";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
  // backgroundColor: "red",
  // marginLeft: "-8px",
});

const Main = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 2,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP - 15,
  },
  [theme.breakpoints.down("lg")]: {
    paddingTop: APP_BAR_MOBILE - 5,
  },
  [theme.breakpoints.down("xs")]: {
    paddingTop: APP_BAR_DESKTOP - 15,
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  let serializedObject = sessionStorage?.getItem("userDetails");
  const userDetails =
    serializedObject !== undefined ? JSON?.parse(serializedObject) : null;
  let token = userDetails?.token?.accessToken;
  const [tokenData, setToken] = useState(token);
  useEffect(() => {
    if (userDetails === null || userDetails == undefined) {
      navigate("/");
    }
  }, [tokenData]);
  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />
      <Nav openNav={open} onCloseNav={() => setOpen(false)} />
      <Main sx={{ bgcolor: "#FAF2FF" }}>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
