import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AppLogo from "../../assets/images/logo.svg";
import LayoutLogo from "../../assets/images/topDesign.svg";
import "../../App.css";
import { useNavigate } from "react-router-dom";
import LeftSideImage from "../../commonUses/leftSideImage";
import PasswordArea from "../../components/passwordField";
import LockIcon from "../../assets/images/lockIcon.svg";
import { passwordChangeApi } from "../../api/adminApi";
import { StyledSubmitButton } from "../../StyledComponents/StyledComponents";
export default function PasswordChangePage() {
  const isVerified = sessionStorage.getItem("isVerified");
  const navigate = useNavigate();
  useEffect(() => {
    if (
      emailFromSession == null ||
      isVerified === "false" ||
      isVerified === null
    ) {
      navigate("/sendOtp");
    }
  }, []);
  const [oldPassword, setOldPassword] = useState("");
  const [oldPasswordError, setOldPasswordError] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const emailFromSession = sessionStorage.getItem("Email");

  const handleOldPasswordChange = (e) => {
    const inputValue = e.target.value;
    setOldPassword(inputValue);
    setOldPasswordError("");
  };

  const handleNewPasswordChange = (e) => {
    const inputValue = e.target.value;
    setNewPassword(inputValue);
    setNewPasswordError("");
  };

  const handleConfirmPasswordChange = (e) => {
    const inputValue = e.target.value;
    setConfirmPassword(inputValue);
    setConfirmPasswordError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = sessionStorage.getItem("Email");
    let credantial = {
      email,
      oldPassword,
      newPassword,
      confirmPassword,
    };

    if (oldPassword.length === 0) {
      setOldPasswordError("*Please Enter Old Password");
    }
    if (newPassword.length === 0) {
      setNewPasswordError("*Please Enter New Password");
    }
    if (confirmPassword.length === 0) {
      setConfirmPasswordError("*Please Enter Confirm Password");
    } else {
      let apiData = await passwordChangeApi(credantial);

      switch (apiData?.data?.message) {
        case "Password Not Matched":
          setConfirmPasswordError("*Password Not Matched");
          break;
        case "Invalid Password":
          setOldPasswordError("Incorrect Password");
          break;
        default:
          break;
      }
      if (apiData?.data?.data === "Password Matched") {
        sessionStorage.setItem("isVerified", "false");
        navigate("/");
      }
    }
  };

  const handleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  var oldVisibleIcon =
    showOldPassword === true ? { color: "#8B8B8B" } : { color: "#CBCBCB" };
  var newVisibleIcon =
    showNewPassword === true ? { color: "#8B8B8B" } : { color: "#CBCBCB" };
  var confirmVisibleIcon =
    showConfirmPassword === true ? { color: "#8B8B8B" } : { color: "#CBCBCB" };

  var oldPasswordVisible = oldPassword.length < 1 ? LockIcon : null;
  var newPasswordVisible = newPassword.length < 1 ? LockIcon : null;
  var confirmPasswordVisible = confirmPassword.length < 1 ? LockIcon : null;

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <LeftSideImage />
      <Grid item xs={12} sm={12} md={12} lg={5}>
        <img src={LayoutLogo} alt="Layout" style={{ float: "right" }} />
        {/* <div className="contain"> */}
        <Grid container direction="column" alignItems="center" rowSpacing={3}>
          <Grid item>
            <Grid
              container
              direction={"column"}
              alignItems="center"
              rowSpacing={2}
            >
              <Grid item>
                <img src={AppLogo} alt="AppLogo" />
              </Grid>
              <Grid item>
                <Typography component={"span"} variant={"body2"}>
                  <p className="topFont">Change Password</p>
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <form onSubmit={handleSubmit}>
              <Grid
                container
                direction={"column"}
                rowGap={3}
                // sx={{ bgcolor: "red" }}
                // alignItems={"center"}
              >
                <Grid item>
                  <label className="lableText">Old Password</label>
                  <PasswordArea
                    id={"oldPassword"}
                    show={showOldPassword}
                    password={oldPassword}
                    handlePasswordChange={handleOldPasswordChange}
                    error={Boolean(oldPasswordError)}
                    helperText={oldPasswordError}
                    onClick={handleOldPasswordVisibility}
                    styleColor={oldVisibleIcon}
                    passwordVisible={oldPasswordVisible}
                  />
                </Grid>
                <Grid item>
                  <label className="lableText">New Password</label>
                  <PasswordArea
                    id={"newPassword"}
                    show={showNewPassword}
                    password={newPassword}
                    handlePasswordChange={handleNewPasswordChange}
                    error={Boolean(newPasswordError)}
                    helperText={newPasswordError}
                    onClick={handleNewPasswordVisibility}
                    styleColor={newVisibleIcon}
                    passwordVisible={newPasswordVisible}
                  />
                </Grid>
                <Grid item>
                  <label className="lableText">Confirm Password</label>
                  <PasswordArea
                    id={"confirmPassword"}
                    show={showConfirmPassword}
                    password={confirmPassword}
                    handlePasswordChange={handleConfirmPasswordChange}
                    error={Boolean(confirmPasswordError)}
                    helperText={confirmPasswordError}
                    onClick={handleConfirmPasswordVisibility}
                    styleColor={confirmVisibleIcon}
                    passwordVisible={confirmPasswordVisible}
                  />
                </Grid>
                <Grid item>
                  <StyledSubmitButton type="submit" fullWidth>
                    Change password
                  </StyledSubmitButton>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
        {/* </div> */}
      </Grid>
    </Grid>
  );
}
