import { Helmet } from "react-helmet-async";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Button, Typography, Container, Box } from "@mui/material";
import Error from "../assets/images/404_error.svg";
// ----------------------------------------------------------------------

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  // minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Page404() {
  return (
    <>
      <Helmet>
        <title> 404 Page Not Found | Minimal UI </title>
      </Helmet>

      <Container>
        <StyledContent
          sx={{ textAlign: "center", alignItems: "center", padding: "50px" }}
        >
          <Typography
            sx={{
              padding: "30px",
            }}
            variant="h3"
            paragraph
          >
            Coming Soon...
          </Typography>

          <Typography variant="h5" paragraph sx={{ color: "text.secondary" }}>
            This page is under construction.
          </Typography>

          <Box
            component="img"
            src={Error}
            sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
          />

          <Button
            to="/Dashboard"
            size="large"
            variant="contained"
            component={RouterLink}
          >
            Go to Home
          </Button>
        </StyledContent>
      </Container>
    </>
  );
}
